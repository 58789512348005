import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Swal from 'sweetalert2';
import '../RegistrationTable.css';
import { getServices } from './services'; 
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        email: '',
        oldPassword: '',
        newPassword: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        oldPassword: '',
        newPassword: '',
    });

    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
        setErrors({
            ...errors,
            [name]: '', 
        });
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field],
        });
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!formData.oldPassword) {
            newErrors.oldPassword = 'Old password is required';
        }

        if (!formData.newPassword) {
            newErrors.newPassword = 'New password is required';
        } else if (formData.newPassword.length < 6) {
            newErrors.newPassword = 'Password should be at least 6 characters long';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateForm()) {
            return; 
        }
    
        try {
            const response = await getServices('admin/change-password/', {
                email: formData.email,
                oldPassword: formData.oldPassword, 
                newPassword: formData.newPassword,
            });
    
            const data = response.data.data; 
    
            if (response.status === 200) {
                if (response.token) {
                    Cookies.set('klsajdfaklsfjaslkfjasl', response.token, { expires: 1 });
                }
    
                Swal.fire({
                    icon: 'success',
                    title: 'Password Changed Successfully',
                    text: data.msg || 'Your password has been updated successfully!',
                });
    
                setFormData({
                    email: '',
                    oldPassword: '',
                    newPassword: '',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Change Password Failed',
                    text: data.msg || 'Something went wrong. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong during the password change process. Please try again later.',
            });
        }
    };
    

    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div className="container">
                    <h1 className="my-4">Change Password</h1>
                    <form onSubmit={handleSubmit} className="registration-form shadow p-4">
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder='Enter Email Id'
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="col-md-6 position-relative">
                                <label htmlFor="oldPassword">Old Password</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword.oldPassword ? 'text' : 'password'}
                                        id="oldPassword"
                                        name="oldPassword"
                                        placeholder='Enter Old Password'
                                        value={formData.oldPassword}
                                        onChange={handleChange}
                                        className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                                        
                                    />
                                    <div className="input-group-append d-flex">
                                        <span
                                            className="input-group-text"
                                            onClick={() => togglePasswordVisibility('oldPassword')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FontAwesomeIcon icon={showPassword.oldPassword ? faEyeSlash : faEye} />
                                        </span>
                                    </div>
                                </div>
                                {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                            </div>
                            <div className="col-md-6 mt-4 mb-2 position-relative">
                                <label htmlFor="newPassword">New Password</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder='Enter New Password'
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                                        
                                    />
                                    <div className="input-group-append d-flex">
                                        <span
                                            className="input-group-text"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FontAwesomeIcon icon={showPassword.newPassword ? faEyeSlash : faEye} />
                                        </span>
                                    </div>
                                </div>
                                {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                            </div>
                        </div>
                        <div className="col-md-2 mt-4 mb-2">
                            <button type="submit" className="btn btn-primary">Change Password</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default ChangePassword;
