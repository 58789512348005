import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Useregistration from './Components/Useregistration';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import Userregistrationinfo from './Components/Userregistrationinfo';
import UserDetail from './Components/UserDetail';
import Registration from './Components/Registration';
import ChangePassword from './Components/ChangePassword';

const checkAuthToken = () => {
  const cookies = document.cookie.split('; ');
  const token = cookies.find(row => row.startsWith('klsajdfaklsfjaslkfjasl='));
  return !!token;
};

function App() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const tokenExists = checkAuthToken();
    setIsAuth(tokenExists);
  }, []);

  return (
    <Routes>
      <Route path='/upsi-declaration' element={<Useregistration />} />
      <Route
        path='/login'
        element={isAuth ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        path='/dashboard'
        element={isAuth ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route
        path='/userregistrationinfo'
        element={
          isAuth ? <Userregistrationinfo /> : <Navigate to="/dashboard" />
        }
      />
      <Route
        path='/user/:userId'
        element={isAuth ? <UserDetail /> : <Navigate to="/login" />}
      />
      <Route
        path='/changePassword'
        element={isAuth ? <ChangePassword /> : <Navigate to="/login" />}
      />
      <Route path='*' element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
