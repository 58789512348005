import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export async function getServices(url, parameter) {
  let token = Cookies.get("klsajdfaklsfjaslkfjasl");
  const API_URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_MODE + url
      : process.env.REACT_APP_PRO_MODE + url;

  try {
    const response = await axios.post(API_URL, parameter, {
      headers: {
        authorization: token,
        // Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
    });

    if (
      response.status === 401 || response.error ==="jwt expired"
    ) {
      Swal.fire({
        title: "Session Expired",
        text: "Your session has expired. Please log in again.",
        icon: "warning",
        confirmButtonText: "Login"
      }).then(() => {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove('klsajdfaklsfjaslkfjasl');
        window.location.href = "/login";
      });
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        title: "Session Expired",
        text: "Your session has expired. Please log in again.",
        icon: "warning",
        confirmButtonText: "Login"
      }).then(() => {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove('klsajdfaklsfjaslkfjasl');
        window.location.href = "/login";
      });
    } else {
      console.error("Error occurred:", error); // Log the error for debugging
      return error.response
        ? error.response
        : { status: "error", message: "Something went wrong!" };
    }
  }
}

export async function getServicesGETMethod(url, parameter) {
  let token = Cookies.get("klsajdfaklsfjaslkfjasl");
  let content = "application/json";
  const API_URL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_MODE + url
      : process.env.REACT_APP_PRO_MODE + url;

  try {
    const response = await axios.get(API_URL, {
      headers: {
        Accept: "application/json, text/plain, */*",
        authorization: token,
        "Content-Type": "application/json"
      },
    });

    if (
      response.status === 401 || response.error ==="jwt expired"
    ) {
      Swal.fire({
        title: "Session Expired",
        text: "Your session has expired. Please log in again.",
        icon: "warning",
        confirmButtonText: "Login"
      }).then(() => {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove('klsajdfaklsfjaslkfjasl');
        window.location.href = "/login"; 
      });
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove('klsajdfaklsfjaslkfjasl');

    } else {
      console.error("Error occurred:", error); // Log the error for debugging
      return error.response
        ? error.response
        : { status: "error", message: "Something went wrong!" };
    }
  }
}
