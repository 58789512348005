// src/Components/UserDetail.js
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useParams, useNavigate } from "react-router-dom";
import blueCircle from "../images/blueCircle.gif";
import Cookies from "js-cookie";
import { getServices } from "./services";

const UserDetail = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const response = await getServices("admin/get-declaration-details/", {
          id: userId,
        });

        if (response.status !== 200) {
          throw new Error("API failed");
        }

        const data = response.data.data;

        if (response.token) {
          Cookies.set("klsajdfaklsfjaslkfjasl", response.token, { expires: 1 });
        }

        const { declaring_person, recipients } = data;

        setUserData({
          id: declaring_person.id,
          first_name: declaring_person.full_name,
          email: declaring_person.email,
          pan: declaring_person.pan,
          remark: declaring_person.remark,
          declaring_otherPurpose: declaring_person.declaring_otherPurpose,
          declaring_other_identity: declaring_person.declaring_other_identity,
          declaring_otherIdentifier: declaring_person.declaring_otherIdentifier,
          declaring_other_identity_number:
            declaring_person.declaring_other_identity_number,
          start_date: declaring_person.start_date,
          end_date: declaring_person.end_date,
          mail_content: declaring_person.mail_content,
          declaring_designation: declaring_person.declaring_designation,
          declaring_department: declaring_person.declaring_department,
          declaring_purpose_upsi_shared:
            declaring_person.declaring_purpose_upsi_shared,
          declaring_purpose_upsi_shared_id:
            declaring_person.declaring_purpose_upsi_shared_id,
          declaring_nature_of_upsi_shared:
            declaring_person.declaring_nature_of_upsi_shared,
          declaring_nature_of_upsi_shared_id:
            declaring_person.declaring_nature_of_upsi_shared_id,
          declaring_otherUPSI: declaring_person.declaring_otherUPSI,

          recipients: recipients.map((recipient) => ({
            email: recipient.email,
            name: recipient.full_name,
            designation: recipient.designation,
            department: recipient.department,
            pan: recipient.pan,
            organization_name: recipient.organization,
            organization_pan: recipient.organization_pan,
            self_identifier_type_if_no_pan:
              recipient.self_identifier_type_if_no_pan,
            self_other_identity_if_no_pan:
              recipient.self_other_identity_if_no_pan,
            self_other_identity_number_if_no_pan:
              recipient.self_other_identity_number_if_no_pan,

            if_no_organization_pan_then_other:
              recipient.if_no_organization_pan_then_other,
            other_than_organization_pan_identifier_type:
              recipient.other_than_organization_pan_identifier_type,
            other_than_organization_pan_identifier_number:
              recipient.other_than_organization_pan_identifier_number,
          })),
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserData(null);
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [userId]);

  const handleBackClick = () => {
    navigate("/userregistrationinfo");
  };

  const downloadCSV = () => {
    const headers = [
      "User Id",
      "Name",
      "Email",
      "PAN",
      "Other Identifier Type",
      "Other Identifier Type (In Case Of Other)",
      "Other Identifier Number",
      "Designation",
      "Department",
      "Purpose of UPSI Sharing",
      "Purpose of UPSI Sharing (Other)",
      "Nature of UPSI Shared",
      "Nature of UPSI Shared (Other)",
      "Start Date",
      "End Date",
      "Remark",
      "Recipient Email",
      "Recipient Name",
      "Recipient PAN",
      "Recipient Identifier Type",
      "Recipient Identifier (Other)",
      "Recipient Identifier Number",
      "Recipient Designation",
      "Recipient Department",
      "Recipient Organization Name",
      "Recipient Organization PAN",
      "Recipient Organization Other Identifier",
      "Recipient Organization Other Identifier Type",
      "Recipient Organization Identifier Number",
    ];

    const csvRows = [];
    csvRows.push(headers.join(",")); // Add header row

    // Add user (declaring person) data as the first row
    const values = [
      userData.id,
      userData.first_name,
      userData.email,
      userData.pan,
      userData.declaring_other_identity,
      userData.declaring_otherIdentifier,
      userData.declaring_other_identity_number,
      userData.declaring_designation,
      userData.declaring_department,
      userData.declaring_purpose_upsi_shared,
      userData.declaring_otherPurpose,
      userData.declaring_nature_of_upsi_shared,
      userData.declaring_otherUPSI,
      userData.start_date,
      userData.end_date,
      userData.remark,
    ];

    csvRows.push(values.join(",")); // Add declaring person data row

    // Add each recipient data as a new row, keeping the declaring person's fields empty
    userData.recipients.forEach((recipient, index) => {
      const recipientValues = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        recipient.email,
        recipient.name,
        recipient.pan,
        recipient.self_identifier_type_if_no_pan,
        recipient.self_other_identity_if_no_pan,
        recipient.self_other_identity_number_if_no_pan,
        recipient.designation,
        recipient.department,
        recipient.organization_name,
        recipient.organization_pan,
        recipient.if_no_organization_pan_then_other,
        recipient.other_than_organization_pan_identifier_type,
        recipient.other_than_organization_pan_identifier_number,
      ];
      csvRows.push(recipientValues.join(","));
    });

    // Create a Blob and download the CSV
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `user_details_${userId}.csv`);
    a.style.visibility = "hidden";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    button: {
      padding: "10px 20px",
      // backgroundColor: '#007bff',
      backgroundColor: "#4c4c51",
      // color: 'white',
      color: "#cfaf31",
      border: "#4c4c51",
      // border: 'none',
      borderRadius: "5px",
      cursor: "pointer",
      marginRight: "10px",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      marginTop: "20px",
    },
    loadingImage: {
      width: "100px",
      height: "100px",
    },
    table: {
      width: "100%",
      marginTop: "20px",
      borderCollapse: "collapse",
    },
    th: {
      padding: "12px",
      textAlign: "left",
      backgroundColor: "#f2f2f2",
      width: "30%", //new
    },
    td: {
      padding: "12px",
      textAlign: "left",
      borderBottom: "1px solid #ddd",
    },
  };

  return (
    <div className="dashboard">
      <Sidebar />
      <main className="main-content">
        <Header />
        <div style={styles.container}>
          <div style={styles.header}>
            <h1 style={styles.title}>
              Details of the Employee sharing the UPSI
            </h1>
            <div>
              <button style={styles.button} onClick={handleBackClick}>
                Back
              </button>
              <button style={styles.button} onClick={downloadCSV}>
                Download CSV
              </button>
            </div>
          </div>

          {/* Loading Spinner or Table */}
          {isLoading ? (
            <div style={styles.loadingContainer}>
              <img
                src={blueCircle}
                alt="Loading..."
                style={styles.loadingImage}
              />
            </div>
          ) : userData ? (
            <>
              <table className="table table-bordered" style={styles.table}>
                <tbody>
                  {/* <tr>
                    <th style={styles.th}>User Id</th>
                    <td style={styles.td}>{userData.id}</td>
                  </tr> */}
                  <tr>
                    <th style={styles.th}>Full Name</th>
                    <td style={styles.td}>{userData.first_name}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>Email Id</th>
                    <td style={styles.td}>{userData.email}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>PAN</th>
                    <td style={styles.td}>{userData.pan}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>Other Identifier Type</th>
                    <td style={styles.td}>
                      {userData.declaring_other_identity}
                    </td>
                  </tr>
                  <tr>
                    <th style={styles.th}>
                      Other Identifier Type (In Case Of Other)
                    </th>
                    <td style={styles.td}>
                      {userData.declaring_otherIdentifier}
                    </td>
                  </tr>
                  <tr>
                    <th style={styles.th}>
                      Other Identifier Number Issued By The Government
                    </th>
                    <td style={styles.td}>
                      {userData.declaring_other_identity_number}
                    </td>
                  </tr>
                  <tr>
                    <th style={styles.th}>Designation</th>
                    <td style={styles.td}>{userData.declaring_designation}</td>
                  </tr>

                  <tr>
                    <th style={styles.th}>Department</th>
                    <td style={styles.td}>{userData.declaring_department}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>Purpose for which UPSI is shared</th>
                    <td style={styles.td}>
                      {userData.declaring_purpose_upsi_shared}
                    </td>
                  </tr>
                  {userData.declaring_purpose_upsi_shared_id === "5001" && (
                    <tr>
                      <th style={styles.th}>
                        Purpose for which UPSI is shared (In Case Of Other)
                      </th>
                      <td style={styles.td}>
                        {userData.declaring_otherPurpose}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <th style={styles.th}>Nature of UPSI shared</th>
                    <td style={styles.td}>
                      {userData.declaring_nature_of_upsi_shared}
                    </td>
                  </tr>
                  {userData.declaring_nature_of_upsi_shared_id === "5001" && (
                    <tr>
                      <th style={styles.th}>
                        Nature of UPSI shared (In Case Of Other)
                      </th>
                      <td style={styles.td}>{userData.declaring_otherUPSI}</td>
                    </tr>
                  )}

                  <tr>
                    <th style={styles.th}>Start Date</th>
                    <td style={styles.td}>{userData.start_date}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>End Date</th>
                    <td style={styles.td}>{userData.end_date}</td>
                  </tr>
                  <tr>
                    <th style={styles.th}>Remark</th>
                    <td style={styles.td}>{userData.remark}</td>
                  </tr>
                </tbody>
              </table>

              {/* Recipients Table */}
              <h2 style={{ marginTop: "40px" }}>
                Recipient Details With Whom UPSI Is Being Shared
              </h2>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table table-bordered table-responsive"
                  style={styles.table}
                >
                  <thead>
                    <tr>
                      <th style={styles.th}>Sr. No.</th>
                      <th style={styles.th}>Email Id</th>
                      <th style={styles.th}>Full Name</th>
                      <th style={styles.th}>PAN</th>
                      <th style={styles.th}>Other Identifier Type</th>
                      <th style={styles.th}>
                        Other Identifier Type (In Case Of Other)
                      </th>
                      <th style={styles.th}>
                        Other Identifier Number Issued By The Government
                      </th>
                      <th style={styles.th}>Designation</th>
                      <th style={styles.th}>Department </th>
                      <th style={styles.th}>
                        Name of the Organization/Entity with which the Person
                        receiving the UPSI is associated
                      </th>
                      <th style={styles.th}>
                        PAN of the Organization/Entity with whom the receiving
                        Person is associated
                      </th>
                      <th style={styles.th}>
                        Provide any other identifier of the Organization
                        authorized by law only where PAN is not available
                      </th>
                      <th style={styles.th}>
                        Other Identifier Type (In Case Of Other)
                      </th>
                      <th style={styles.th}>
                        Other Identifier Number Issued By The Government
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.recipients.map((recipient, index) => (
                      <tr key={index}>
                        <td style={styles.td}>{index + 1}</td>
                        <td style={styles.td}>{recipient.email}</td>
                        <td style={styles.td}>{recipient.name}</td>
                        <td style={styles.td}>{recipient.pan}</td>

                        {recipient.self_identifier_type_if_no_pan ===
                          "50001" && (
                          <>
                            <td style={styles.td}>
                              {recipient.self_identifier_type_if_no_pan}
                            </td>
                            <td style={styles.td}>
                              {recipient.self_other_identity_if_no_pan}
                            </td>
                            <td style={styles.td}>
                              {recipient.self_other_identity_number_if_no_pan}
                            </td>
                          </>
                        )}
                        <td style={styles.td}>{recipient.designation}</td>

                        <td style={styles.td}>{recipient.department}</td>
                        <td style={styles.td}>{recipient.organization_name}</td>
                        <td style={styles.td}>{recipient.organization_pan}</td>

                        {recipient.if_no_organization_pan_then_other ===
                          "5001" && (
                          <>
                            <td style={styles.td}>
                              {recipient.if_no_organization_pan_then_other}
                            </td>
                            <td style={styles.td}>
                              {
                                recipient.other_than_organization_pan_identifier_type
                              }
                            </td>
                            <td style={styles.td}>
                              {
                                recipient.other_than_organization_pan_identifier_number
                              }
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p>No user data available</p>
          )}

          <div className="section-wrapper mt-5">
            <div className="section-title">
              <h5 className="mt-1 form_lable">
                Confidentiality clause Email content
              </h5>
            </div>

            <div className="form-group1 mt-4">
              <label className="form_label" htmlFor="remark">
                Email
              </label>
              <p>
                {userData
                  ? userData.mail_content.replace(/<\/?[^>]+(>|$)/g, "")
                  : ""}
              </p>
            </div>
          </div>

          <div className="mb-3 form-check mt-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={isCheckboxChecked}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Due notice has been given to the recipient to maintain
              confidentiality of the UPSI and refrain from dealing in securities
              of Eicher Motors Limited in compliance with SEBI (Prohibition of
              Insider Trading) Regulations
            </label>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserDetail;
