import React from 'react';

const StatCard = ({ icon, title, value }) => {
    return (
        <div className="stat-card glow">
            <i className={icon}></i>
            <div className="stat-info">
                <h3>{title}</h3>
                <p className='show_count'>{value}</p>
            </div>
        </div>
    );
};

export default StatCard;
