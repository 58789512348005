import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import StatCard from './StatCard';
import { getServices } from './services';  
import Cookies from 'js-cookie';

const Dashboard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({
        declaringPersons: 0,
        recipientPersons: 0,
    });

    // Fetch the data from the API
    useEffect(() => {
        const fetchDashboardData = async () => {
            const token = Cookies.get('klsajdfaklsfjaslkfjasl');
            
            if (!token) {
                window.location.href = '/login';
                return;
            }

            try {
                const response = await getServices('admin/get-dashboard-count/', {}, token);

                if (response && response.data && response.data.data) {
                    // Update the state with the data received from the API
                    setDashboardData({
                        declaration_header: response.data.data.declaration_header,
                        declaration_details: response.data.data.declaration_details,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch dashboard data:", error);
                window.location.href = '/login';
            }
        };

        fetchDashboardData();
    }, []);

     // Function to handle click event
     const handleDeclaringPersonsClick = () => {
        navigate('/userregistrationinfo'); 
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div id="dashboard" className="page-content active">
                    <h2 className='mb-5 d-flex justify-content-start'>Dashboard</h2>

                    {/* Bootstrap grid layout */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 mb-4" onClick={handleDeclaringPersonsClick} style={{ cursor: 'pointer' }}>
                            <StatCard icon="fas fa-users" title="Declarations" value={dashboardData.declaration_header} />
                            </div>
                            {/* <div className="col-lg-3 col-md-6 mb-4" onClick={handleDeclaringPersonsClick} style={{ cursor: 'pointer' }}>
                                <StatCard icon="fas fa-users" title="Recipient Persons" value={dashboardData.recipientPersons} />
                            </div> */}
                            {/* <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-shopping-bag" title="Total Sales" value="$124,563" />
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-chart-line" title="Revenue" value="$67,895" />
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-percent" title="Growth" value="+24.5%" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;
