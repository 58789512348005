import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2'; 
import { getServices } from './services'; 

const Sidebar = () => {
  const logout = async () => {
    try {
      const response = await getServices('admin/logout', { }); 

      if (response.status === 200) {
        Cookies.remove("klsajdfaklsfjaslkfjasl"); 
        window.location.href = "/login"; 
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Logout Failed',
          text: response.data.msg || 'Something went wrong. Please try again.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to log out. Please try again later.',
      });
    }
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-header d-flex justify-content-center">
        {/* <img src="https://via.placeholder.com/50" alt="Logo" className="logo" /> */}
        <h2>Admin</h2>
      </div>
      <ul className="nav-links">
        <li data-page="dashboard">
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-home"></i>Dashboard
          </NavLink>
        </li>
        <li data-page="userregistrationinfo">
          <NavLink 
            to="/userregistrationinfo" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-table"></i>Declarations
          </NavLink>
        </li>
        {/* <li data-page="changePassword">
          <NavLink 
            to="/changePassword" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-lock"></i>Change Password
          </NavLink>
        </li> */}
        
        {/* <li data-page="registration">
          <NavLink 
            to="/registration" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-file-alt"></i>Registration Form
          </NavLink>
        </li> */}
        
        {/* <li 
          data-page="logout" 
          onClick={logout} 
          style={{ cursor: 'pointer', color: 'var(--text-secondary)' }} // Added inline CSS
        >
          <i className="fas fa-sign-out-alt"></i>Logout
        </li> */}
        <li data-page="logout">
          <NavLink 
            to="/logout" 
            onClick={logout} 
            className={({ isActive }) => (isActive ? 'active' : '')}
            // style={{ color: 'var(--text-secondary)', cursor: 'pointer' }}
          >
            <i className="fas fa-sign-out-alt"></i>Logout
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
