import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { getServices } from './services'; 
import '../login.css';

const Login = () => {
  const [signInData, setSignInData] = useState({ email: '', password: '' });
  const [signInErrors, setSignInErrors] = useState({});

  useEffect(() => {
      const signUpButton = document.getElementById('signUp');
      const signInButton = document.getElementById('signIn');
      const container = document.getElementById('container');

      if (signUpButton && signInButton && container) {
          signUpButton.addEventListener('click', () => {
              container.classList.add("right-panel-active");
          });

          signInButton.addEventListener('click', () => {
              container.classList.remove("right-panel-active");
          });

          return () => {
              signUpButton.removeEventListener('click', () => {
                  container.classList.add("right-panel-active");
              });

              signInButton.removeEventListener('click', () => {
                  container.classList.remove("right-panel-active");
              });
          };
      }
  }, []);

  const [signUpData, setSignUpData] = useState({ email: '' });
  const [signUpErrors, setSignUpErrors] = useState({});

  useEffect(() => {
      const signUpButton = document.getElementById('signUp');
      const signInButton = document.getElementById('signIn');
      const container = document.getElementById('container');

      if (signUpButton && signInButton && container) {
          signUpButton.addEventListener('click', () => {
              container.classList.add("right-panel-active");
          });

          signInButton.addEventListener('click', () => {
              container.classList.remove("right-panel-active");
          });

          return () => {
              signUpButton.removeEventListener('click', () => {
                  container.classList.add("right-panel-active");
              });

              signInButton.removeEventListener('click', () => {
                  container.classList.remove("right-panel-active");
              });
          };
      }
  }, []);

  const handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'signIn') {
        setSignInData({ ...signInData, [name]: value });
    } else {
        setSignUpData({ ...signUpData, [name]: value });
    }
};
  const validateSignUp = () => {
      const errors = {};

      if (!signUpData.email) {
          errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
          errors.email = 'Email is invalid';
      }

      setSignUpErrors(errors);
      return Object.keys(errors).length === 0;
  };

  // Handle Forgot Password form submission
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    if (validateSignUp()) {
        try {
            const parameters = { email: signUpData.email };

            const response = await getServices('admin/forgot-password/', parameters);
            console.log('response', response);
            console.log('response.message', response.message);

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Reset Successfully!',
                    text: 'Please check your email for the new password.',
                });
            }
            else if (response.status === 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Admin Not Found',
                    text: 'Admin not created yet.',
                });
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: response.message || 'Something went wrong. Please try again.',
                });
            }
        } catch (error) {
            let errorMessage = 'Something went wrong. Please try again later.';

            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
            });
        }
    }
};


  const validateSignIn = () => {
      const errors = {};
      if (!signInData.email) {
          errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(signInData.email)) {
          errors.email = 'Email is invalid';
      }
      if (!signInData.password) {
          errors.password = 'Password is required';
      }
      setSignInErrors(errors);
      return Object.keys(errors).length === 0;
  };

  // Handle Sign Up form submission
  const handleSignUpSubmit = (e) => {
      e.preventDefault();
      if (validateSignUp()) {
          Swal.fire({
              icon: 'success',
              title: 'Sign Up Successful!',
              text: 'Account created successfully.',
          });
      }
  };

  // Handle Sign In form submission
  const handleSignInSubmit = async (e) => {
    e.preventDefault();

    if (validateSignIn()) {
        try {
            const parameters = {
                email: signInData.email,
                password: signInData.password,
            };

            const response = await getServices('admin/login/', parameters);
            // console.log("API Response", response); 

            if (response.data.auth) {
                const token = response.data.token; 

                Cookies.set('klsajdfaklsfjaslkfjasl', token, { expires: 1 });

                // Success: handle the login response
                Swal.fire({
                    icon: 'success',
                    title: 'Sign In Successful!',
                    text: 'Logged in successfully.',
                });

                window.location.href = "/dashboard";
            } else {
                // Handle login failure
                Swal.fire({
                    icon: 'error',
                    title: 'Sign In Failed!',
                    text: 'Invalid email or password.',
                });
            }
        } catch (error) {
            // Handle API error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong during login. Please try again later.',
            });
        }
    }
};

  return (
      <div className='login-page mt-4'>
          <div className="container login-page-container" id="container">
              <div className="form-container sign-up-container">
              <form className='login-form' onSubmit={handleForgotPasswordSubmit}>
                      <h1 className='mb-4'>Forgot Password</h1>
                      <input
                          className='login-form-input'
                          type="text"
                          placeholder="Enter Your Email"
                          name="email"
                          value={signUpData.email}
                          onChange={handleInputChange}
                      />
                      {signUpErrors.email && <p className="error">{signUpErrors.email}</p>}
                      <button className='mt-4' type="submit">Send</button>
                  </form>
              </div>

              <div className="form-container sign-in-container">
                  <form className='login-form' onSubmit={handleSignInSubmit}>
                      <h1>Sign In</h1>
                      {/* <div className="social-container">
                          <a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                      </div>
                      <span>or use your account</span> */}
                      <input
                          className='login-form-input'
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={signInData.email}
                          onChange={(e) => handleInputChange(e, 'signIn')}
                      />
                      {signInErrors.email && <p className="error">{signInErrors.email}</p>}
                      <input
                          className='login-form-input'
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={signInData.password}
                          onChange={(e) => handleInputChange(e, 'signIn')}
                      />
                      {signInErrors.password && <p className="error">{signInErrors.password}</p>}
                      {/* <a href="#">Forgot your password?</a> */}
                      <button type="submit" className='mt-2'>Sign In</button>
                  </form>
              </div>

              <div className="overlay-container">
                  <div className="overlay">
                      <div className="overlay-panel overlay-left">
                          <h1>Welcome Back!</h1>
                          <p>To keep connected with us please login with your personal info</p>
                          <button className="ghost" id="signIn">Sign In</button>
                      </div>
                      <div className="overlay-panel overlay-right">
                          <h1>UPSI Declaration!</h1>
                          <p>If you forgot your password, enter your registered email and get a new password.</p>
                          <button className="ghost" id="signUp">Forgot Password</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Login;