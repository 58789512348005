import React, { useState, useEffect } from 'react';
import './userregistration.css'; 
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { getServices, getServicesGETMethod } from './services'; 
import Swal from 'sweetalert2';

const Useregistration = () => {
  const initialContent = `"As you are aware that shares of Eicher Motors Limited ('EML') are listed on the stock exchanges in India. EML is obligated to ensure compliance with applicable SEBI
regulations by parties it deals with if such parties gain access to certain information about it that qualifies as Unpublished Price Sensitive Information ("UPSI") as defined in the
'Securities and Exchange Board of India (Prohibition of Insider Trading) Regulations, 2015 ("SEBI Regulations"). You agree that you have received/may receive or gain access to UPSI which can make you a Connected Person as defined in the SEBI Regulations and liable as such to the provisions of the SEBI Regulations. You further agree that the SEBI Regulations, inter alia, prohibit any person who has UPSI about a company from dealing in the securities of such company, except as otherwise permitted in the SEBI Regulations. You undertake that  you shall comply and shall procure that all your agents, advisors, representatives, officers, directors or employees (together, "Representatives") shall comply with all the applicable laws in this regard including the SEBI Regulations. You shall not and procure that your Representatives/immediate relatives shall not, deal or trade in any manner whatsoever in the securities of EML until the UPSI becomes a generally available information or in any other manner which is in violation of the SEBI Regulations."
`;

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [editorData, setEditorData] = useState(initialContent);
  
    useEffect(() => {
      setEditorData(initialContent);
    }, []);
  
    // onEditorDataChange function for email editer
    const onEditorDataChange = (event, editor) => {
      const data = editor.getData();
      setEditorData(data);
    };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // searchable text box
  const [useremail, setEmail] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [userName, setUserName] = useState('');
  const [userpan, setUserPAN] = useState('');
  const [emailData, setEmailData] = useState([]); 

  // Function to fetch email data from the API
  const fetchEmailDataFromAPI = async (email) => {
      const parameters = {
          email: useremail,
      };

      try {
          const response = await getServices('user/get-details-based-on-email-header/', parameters); 

          if (response.status === 200) {
              setEmailData(Array.isArray(response.data.data) ? response.data.data : []);  
          } else {
              console.error('Failed to fetch email data:', response.data.msg);
              setEmailData([]); 
          }
      } catch (error) {
          console.error('Error fetching email data:', error);
          setEmailData([]); 
      }
  };

  // Function to fetch suggestions based on the input
  const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      if (!Array.isArray(emailData)) {
          return [];
      }

      // Return only emails that match the input value
      return inputLength === 0 ? [] : emailData.filter(emailObj =>
          emailObj.email.toLowerCase().includes(inputValue) // Adjusted to match the response structure
      );
  };

  // Called every time the input value changes
  const onSuggestionsFetchRequested = ({ value }) => {
      setSuggestions(getSuggestions(value));
      fetchEmailDataFromAPI(value); // Fetch data whenever user types an email
  };

  // Called when the input value changes to update the input field
  const onChange = (event, { newValue }) => {
      setEmail(newValue);
      handleFieldChange('useremail', newValue); 
  };

  // Called when a suggestion is selected
  const onSuggestionSelected = (event, { suggestion }) => {
      setUserName(suggestion.full_name);  
      setUserPAN(suggestion.pan);           
  };

  // Clears suggestions when they are no longer needed
  const onSuggestionsClearRequested = () => {
      setSuggestions([]);
  };

  // Get suggestion value for rendering in the input field
  const getSuggestionValue = (suggestion) => suggestion.email;

  // How to render each suggestion item
  const renderSuggestion = (suggestion) => (
      <div>{suggestion.email}</div>
  );

  // Reset user data when email field is cleared
  useEffect(() => {
      if (useremail === '') {
          setUserName('');
          setUserPAN('');
      }
  }, [useremail]);

// new start
const [identifierType, setIdentifierType] = useState('');
const [otherIdentifier, setOtherIdentifier] = useState("");

// Handler for when a new option is selected
const handleChangeforindentifier = (event) => {
  // console.log(event)
  setIdentifierType(event.target.value);

  handleFieldChange('identifierType', event.target.value);

  if (event.target.value !== "5001") {
    setOtherIdentifier("");
  }
};

const [identifierValue, setIdentifierValue] = useState('');

const handleIdentifierValueChange = (event) => {
  setIdentifierValue(event.target.value);

  handleFieldChange('identifierValue', event.target.value);
};

const [userdesignation, setUserDesignationValue] = useState('');

const handleDesignationValueChange = (event) => {
  setUserDesignationValue(event.target.value);
};

const [userdepartment, setUserDepartmentValue] = useState('');

const handleDepartmentValueChange = (event) => {
  setUserDepartmentValue(event.target.value);
};               

// new start
  const [selectedPurpose, setSelectedPurpose] = useState('');

  // Handler for when a new option is selected
  // const handleChange = (event) => {
  //   setSelectedPurpose(event.target.value);
  // };

  // new
  const [purposeUPSIOptions, setPurposeUPSIOptions] = useState([]);

  const [otherPurpose, setOtherPurpose] = useState('');
  
  // Fetch UPSI options
  const fetchPurposeUPSIOptions = async () => {
    try {
      const response = await getServicesGETMethod('get-upsi-purpose-options/'); 
      if (response.status === 200) {
        // setPurposeUPSIOptions(
        //   Array.isArray(response.data.data)
        //     ? response.data.data
        //     : []
        // )
        const options = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        // Add the "5001" option
        options.push({ id: '5001', label: '5001' });
        setPurposeUPSIOptions(options);
      } else {
        console.error('Failed to fetch UPSI options:', response.data.msg);
        setPurposeUPSIOptions([]); // Reset UPSI options in case of failure
      }
    } catch (error) {
      console.error('Error fetching UPSI options:', error);
      setPurposeUPSIOptions([]); // Reset UPSI options in case of error
    }
  };

  // Fetch UPSI options when the component mounts
  useEffect(() => {
    fetchPurposeUPSIOptions();
  }, []);

  // Handler for when a new option is selected
  // const handleChange = (selectedOption) => {
  //   // console.log("selectedOption")
  //   // console.log(selectedOption)
  //   setSelectedPurpose(selectedOption ? selectedOption.id : ''); 
  // };

  const handleChange = (selectedOption) => {
    setSelectedPurpose(selectedOption ? selectedOption.id : '');
    if (selectedOption.id === '5001') {
      setOtherPurpose(''); // Clear otherPurpose when "5001" is selected
    }
  };

  // new for Nature of UPSI shared

  const [upsiNatureOptions, setUpsiNatureOptions] = useState([]);
  const [selectedUPSI, setSelectedUPSI] = useState('');
  const [otherUPSI, setOtherUPSI] = useState('');

  // Fetch UPSI nature options
  const fetchupsiNatureOptions = async () => {
    try {
      const response = await getServicesGETMethod('get-upsi-nature-options/'); 
      if (response.status === 200) {
        // setUpsiNatureOptions(
        //   Array.isArray(response.data.data)
        //     ? response.data.data
        //     : []
        // ); 
        const options = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        options.push({ id: '5001', label: '5001' }); // Add "5001" option
        setUpsiNatureOptions(options);
      } else {
        setUpsiNatureOptions([]); // Reset UPSI options in case of failure
      }
    } catch (error) {
      console.error('Error fetching UPSI options:', error);
      setUpsiNatureOptions([]); // Reset UPSI options in case of error
    }
  };

  // Fetch UPSI options when the component mounts
  useEffect(() => {
    fetchupsiNatureOptions();
  }, []);

  // Handler for when a new option is selected
  // const handleUPSISelectChange = (selectedOption) => {
  //   setSelectedUPSI(selectedOption ? selectedOption.id : ''); 
  // };
  const handleUPSISelectChange = (selectedOption) => {
    setSelectedUPSI(selectedOption ? selectedOption.id : ''); // Update the selected UPSI
    if (selectedOption.id === '5001') {
      setOtherUPSI(''); // Clear otherUPSI when "5001" is selected
    }
  };

  // new for Date
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setEndDate('');
  };

  const [remark, setRemark] = useState('');

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);

    handleFieldChange('remark', event.target.value);
  };

  const handleRecipientChange = (index, field, value) => {
    const updatedDetails = recipientDetails.map((detail, i) => {
      if (i === index) {
        return { ...detail, [field]: value };
      }
      return detail;
    });
    setRecipientDetails(updatedDetails);
  };

  const [formData, setFormData] = useState({
    useremail: '',
    userName: '',
    userpan: '',
    userdesignation: '',
    userdepartment: '',
    selectedPurpose: '',
    selectedUPSI: '',
    startDate: '',
    endDate: '',
    remark: '',
    editorData: '',
    recipientDetails: [], 
  });
  

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    let validationErrors = {};

    if (!useremail) {
      validationErrors.useremail = 'Email is required';
    } else if (!emailRegex.test(useremail)) {
      validationErrors.useremail = 'Invalid email format';
    }

    if (!userName) {
      validationErrors.userName = 'Full Name is required';
    }

    if (!userpan && (!identifierType || !identifierValue)) {
      validationErrors.userpan = 'Enter PAN or both Other Identifier fields are required';
      if (!identifierType) {
        validationErrors.identifierType = 'Other Identifier Type is required';
      }
      if (!identifierValue) {
        validationErrors.identifierValue = 'Other Identifier Number is required';
      }
      if (identifierType === "5001" && !otherIdentifier) {
        validationErrors.otherIdentifier = 'Please specify Other Identifier Type';
      }
    }
    else if (userpan && !panRegex.test(userpan)) {
      validationErrors.userpan = 'Invalid PAN format';
    }

    if (!userpan && (!identifierType || !identifierValue)) {
      validationErrors.userpan = 'Enter PAN or both Other Identifier fields are required';
      
      if (!identifierType) {
        validationErrors.identifierType = 'Other Identifier Type is required';
      }
      if (!identifierValue) {
        validationErrors.identifierValue = 'Other Identifier Number is required';
      }
      
      // Check if identifierType is "5001" but otherIdentifier is missing
      if (identifierType === "5001" && !otherIdentifier) {
        validationErrors.otherIdentifier = 'Please specify Other Identifier Type';
      }
    } 
    // If userpan is entered and identifierType is "5001", both identifierType and identifierValue are required
    else if (userpan && identifierType === "5001") {
      if (!identifierValue) {
        validationErrors.identifierValue = 'Other Identifier Number is required';
      }
      if (!otherIdentifier) {
        validationErrors.otherIdentifier = 'Other Identifier Type is required';
      }
    } 
    // If userpan is entered and identifierType is not "5001", only identifierValue is required
    else if (userpan && identifierType && identifierType !== "5001" && !identifierValue) {
      validationErrors.identifierValue = 'Identifier Number is required';
    }
    // Validate PAN format if userpan is entered
    else if (userpan && !panRegex.test(userpan)) {
      validationErrors.userpan = 'Invalid PAN format';
    }
    

    if (!userdesignation) {
      validationErrors.userdesignation = 'Designation is required';
    }
    if (!userdepartment) {
      validationErrors.userdepartment = 'Department is required';
    }
    if (!selectedPurpose) {
      validationErrors.selectedPurpose = 'Purpose is required';
    }
    if (selectedPurpose === '5001' && !otherPurpose) {
      validationErrors.otherPurpose = 'Please specify the purpose';
    }
    if (!selectedUPSI) {
      validationErrors.selectedUPSI = 'Nature of UPSI is required';
    }
    if (selectedUPSI === '5001' && !otherUPSI) {
      validationErrors.otherUPSI = 'Please specify the nature';
    }
    if (!startDate) {
      validationErrors.startDate = 'Start date is required';
    }
    if (!endDate) {
      validationErrors.endDate = 'End date is required';
    }

    if (!editorData || editorData.trim() === '') {
      validationErrors.mail_content = 'Email content is required';
    }
  
    if (!isCheckboxChecked) {
      validationErrors.checkbox = 'You must confirm by checking the box';
    }
    const panSet = new Set();
    if (userpan && panRegex.test(userpan)) {
      panSet.add(userpan);
    }
    let isDuplicatePAN = false;
    let isDuplicateOrgPAN = false;
    const organizationPanSet = new Set();
      // Recipient Details Validation
      if (recipientDetails.length === 0) {
        validationErrors.recipientDetails = 'At least one recipient is required';
    } else {
      recipientDetails.forEach((recipient, index) => {
        // Email validation
        if (!recipient.email) {
            validationErrors[`recipient_${index}_email`] = `Email is required for recipient ${index + 1}.`;
        } else if (!emailRegex.test(recipient.email)) {
            validationErrors[`recipient_${index}_email`] = `Invalid email format for recipient ${index + 1}.`;
        }
    
        // Full name validation
        if (!recipient.full_name) {
            validationErrors[`recipientDetails_${index}_full_name`] = `Recipient ${index + 1} full name is required`;
        }
    
        // Designation validation
        if (!recipient.designation) {
            validationErrors[`recipientDetails_${index}_designation`] = `Recipient ${index + 1} designation is required`;
        }
    
        // Department validation
        if (!recipient.department) {
            validationErrors[`recipientDetails_${index}_department`] = `Recipient ${index + 1} department is required`;
        }
    
        // Organization validation
        if (!recipient.organization) {
            validationErrors[`recipientDetails_${index}_organization`] = `Recipient ${index + 1} organization is required`;
        }
    
        // Organization PAN or Other Identifier validation
        if (!recipient.organization_pan) {
            // If organization PAN is missing, both identifierType and identifierValue are required
            if (!recipient.if_no_organization_pan_then_other || !recipient.other_than_organization_pan_identifier_number) {
                validationErrors[`recipientDetails_${index}_organization_pan`] = `Enter Organization PAN or provide both Other Identifier fields`;
    
                if (!recipient.if_no_organization_pan_then_other) {
                    validationErrors[`recipientDetails_${index}_if_no_organization_pan_then_other`] = 'Organization Identifier Type is required';
                }
                
                if (!recipient.other_than_organization_pan_identifier_number) {
                    validationErrors[`recipientDetails_${index}_other_than_organization_pan_identifier_number`] = 'Organization Identifier Number is required';
                }
    
                // If organization identifierType is "5001", ensure organization otherIdentifier is provided
                if (recipient.if_no_organization_pan_then_other === "5001" && !recipient.other_than_organization_pan_identifier_type) {
                    validationErrors[`recipientDetails_${index}_other_than_organization_pan_identifier_type`] = 'Please specify Other Organization Identifier Type';
                }
            }
        } else {
            // Organization PAN format validation
            if (!panRegex.test(recipient.organization_pan)) {
                validationErrors[`recipient_${index}_organization_pan`] = `Invalid Organization PAN format for recipient ${index + 1}.`;
            } else if (recipient.pan && recipient.organization_pan && recipient.pan === recipient.organization_pan) {
                validationErrors[`recipient_${index}_organization_pan`] = `Recipient ${index + 1} organization PAN cannot be the same as Recipient PAN.`;
            } else if (recipient.organization_pan === userpan) {
                validationErrors[`recipient_${index}_organization_pan`] = `Recipient ${index + 1} organization PAN cannot be the same as declare PAN.`;
                isDuplicateOrgPAN = true;
            } 
            // else if (organizationPanSet.has(recipient.organization_pan)) {
            //     validationErrors[`recipient_${index}_organization_pan`] = `Organization PAN for recipient ${index + 1} must be unique.`;
            // } 
            else {
                organizationPanSet.add(recipient.organization_pan);
            }
        }
    
        // Mail sent option validation
        if (recipient.mail_sent === undefined || recipient.mail_sent === '') {
            validationErrors[`recipientDetails_${index}_mail_sent`] = `Recipient ${index + 1} mail sent option is required`;
        }
    
        // Combined validation for recipient PAN and identifier fields
        if (!recipient.pan) {
            if (!recipient.self_identifier_type_if_no_pan || !recipient.self_other_identity_number_if_no_pan) {
                validationErrors[`recipientDetails_${index}_pan`] = 'Enter PAN or both Other Identifier fields are required';
    
                if (!recipient.self_identifier_type_if_no_pan) {
                    validationErrors[`recipientDetails_${index}_self_identifier_type_if_no_pan`] = 'Other Identifier Type is required';
                }
    
                if (!recipient.self_other_identity_number_if_no_pan) {
                    validationErrors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`] = 'Other Identifier Number is required';
                }
    
                if (recipient.self_identifier_type_if_no_pan === "5001" && !recipient.self_other_identity_if_no_pan) {
                    validationErrors[`recipientDetails_${index}_self_other_identity_if_no_pan`] = 'Please specify Other Identifier Type';
                }
            }
        } else {
            if (!panRegex.test(recipient.pan)) {
                validationErrors[`recipient_${index}_pan`] = `Invalid PAN format for recipient ${index + 1}.`;
            } else if (userpan === recipient.pan) {
                isDuplicatePAN = true;
            } else if (panSet.has(recipient.pan)) {
                validationErrors[`recipient_${index}_pan`] = `PAN for recipient ${index + 1} must be unique.`;
            } else {
                panSet.add(recipient.pan);
            }
        }

         // New condition: Additional requirements based on identifier type when PAN is provided
        if (recipient.self_identifier_type_if_no_pan === "5001") {
          // Both self_other_identity_if_no_pan and self_other_identity_number_if_no_pan are required if identifier type is "5001"
          if (!recipient.self_other_identity_if_no_pan) {
              validationErrors[`recipientDetails_${index}_self_other_identity_if_no_pan`] = 'Please specify Other Identifier Type';
          }
          if (!recipient.self_other_identity_number_if_no_pan) {
              validationErrors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`] = 'Other Identifier Number is required';
          }
        } else if (recipient.self_identifier_type_if_no_pan && recipient.self_identifier_type_if_no_pan !== "5001") {
          // Only self_other_identity_number_if_no_pan is required if identifier type is anything other than "5001"
          if (!recipient.self_other_identity_number_if_no_pan) {
              validationErrors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`] = 'Identifier Number is required';
          }
        }

    });
    }

    if (isDuplicatePAN) {
      Swal.fire({
        icon: 'error',
        title: 'Duplicate PAN Number',
        text: 'Declarant PAN number and Recipient PAN number cannot be the same. Please enter unique PANs.'
      });
      return; 
    }
    // else if (isDuplicateOrgPAN) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Duplicate Organization PAN Detected',
    //     text: 'Your PAN cannot be the same as any recipient’s organization PAN. Please enter a unique PAN.'
    //   });
    //   return;
    // }
    // If there are errors, set them in state and do not submit the form
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // if (recipientDetails.length === 0) {
    //   validationErrors.recipientDetails = 'At least one recipient is required';
    // } else {
    //   recipientDetails.forEach((recipient, index) => {
    //     if (
    //       !recipient.email ||
    //       !recipient.full_name ||
    //       !recipient.designation ||
    //       !recipient.department||
    //       !recipient.pan ||
    //       !recipient.organization ||
    //       !recipient.organization_pan||
    //       !recipient.mail_sent
    //     ) {
    //       validationErrors.recipientDetails = `Please fill all fields for recipient #${index + 1}.`;
    //     }
    //   });
    // }

    try {
      const parameters = {
        email: useremail,
        full_name: userName,
        pan: userpan,
        other_identity: identifierType,
        otherIdentifier:otherIdentifier, //new
        other_identity_number: identifierValue,
        designation: userdesignation,
        department: userdepartment,
        purpose_upsi_shared: selectedPurpose,
        otherPurpose:otherPurpose, //new
        nature_of_upsi_shared: selectedUPSI,
        otherUPSI:otherUPSI, //new
        start_date: startDate,
        end_date: endDate,
        remark: remark,
        recipient_persons: recipientDetails, 
        mail_content: editorData, //new
        // otherIdentifier:otherIdentifier
      };

      const response = await getServices('user/add-user-declaration/', parameters); 
      // console.log('response',response);
      

      if (response.status === 200 || response.status === 201) {
          Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.msg || 'UPSI Declaration Submitted successfully!',
          }).then(() => {
            window.location.reload();
          });

          setFormData({
            useremail: '',
            userName: '',
            userpan: '',
            userdesignation: '',
            userdepartment: '',
            selectedPurpose: '',
            selectedUPSI: '',
            startDate: '',
            endDate: '',
            editorData: '',
            remark: '',
            recipientDetails: [], 
          });
      } else {
          Swal.fire({
              icon: 'error',
              title: 'UPSI Declaration Failed',
              text: response.data.msg || 'Something went wrong. Please try again.',
          });
      }
  } catch (error) {
      Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong during the UPSI Declaration process. Please try again later.',
      });
  }

  };

  const handleFieldChange = (field, value) => {
    const updatedErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Clear error for the field being typed
    if (errors[field]) {
      delete updatedErrors[field];
    } 

    if (field === 'useremail') {
      if (!value || value.trim() === '') {
        updatedErrors.useremail = 'Email is required';  
      } else if (!emailRegex.test(value)) {
        updatedErrors.useremail = 'Invalid email format';  
      } else {
        delete updatedErrors.useremail;  
      }
    }

    if (field === 'userName') {
      if (!value || value.trim() === '') {
        updatedErrors.userName = 'Full Name is required'; 
      } else {
        delete updatedErrors.userName; 
      }
    }

    if (field === 'userpan') {
      if (value) {
        delete updatedErrors.identifierType;
        delete updatedErrors.identifierValue;
      } else {
        if (!identifierType) {
          updatedErrors.identifierType = 'Other Identifier Type is required';
        }
        if (!identifierValue) {
          updatedErrors.identifierValue = 'Other Identifier Number is required';
        }
      }
    } else if (field === 'identifierType' || field === 'identifierValue') {
      if (value) {
        delete updatedErrors.userpan;
      } else if (!userpan) {
        updatedErrors.userpan = 'PAN or both Other Identifier fields are required';
      }
    }

  if (field === 'userdesignation') {
    if (!value || value.trim() === '') {
      updatedErrors.userdesignation = 'Designation is required';
    }
  }
  if (field === 'userdepartment') {
    if (!value || value.trim() === '') {
      updatedErrors.userdepartment = 'Department is required';
    }
  }

  if (field === 'selectedPurpose') {
    if (!value) {
      updatedErrors.selectedPurpose = 'Purpose is required';
    }
  }

  if (field === 'selectedUPSI') {
    if (!value) {
      updatedErrors.selectedUPSI = 'Nature of UPSI is required';
    }
  }

  if (field === 'startDate') {
    if (!value || isNaN(new Date(value).getTime())) {
      updatedErrors.startDate = 'Start date is required';
    }
  }

  if (field === 'endDate') {
    if (!value || isNaN(new Date(value).getTime())) {
      updatedErrors.endDate = 'End date is required';
    }
  }

  if (field === 'mail_content') {
    if (!value || value.trim() === '') {
      updatedErrors.mail_content = 'Email content is required';
    }
  }

  if (field === 'checkbox') {
    if (!value) {
      updatedErrors.checkbox = 'You must confirm by checking the box';
    }
  }
  
    setErrors(updatedErrors);
  };
  

  const otherDocTypeOptions = [
    { value: 'Drivers License', label: 'Drivers License' },
    { value: 'Passport', label: 'Passport' },
    { value: 'Tax ID', label: 'Tax ID' },
  ];
  const otherOrganizationDocTypeOptions = [
    { value: 'GST Number', label: 'GST Number' }
  ];

  // new for Recipient Details
  const [recipientDetails, setRecipientDetails] = useState([
    {
      email: '',
      full_name: '',
      designation: '',
      department: '',
      pan: '',
      organization: '',
      organization_pan: '',
      mail_sent: '1',
      // identifierType: '',
      // identifierValue: '',
      // otherIdentifier: '',
    }
  ]);

  useEffect(() => {
    const duplicatePAN = recipientDetails.some(recipient => recipient.pan && recipient.pan === userpan);
    // const isDuplicateOrgPAN = recipientDetails.some(recipient => recipient.organization_pan && recipient.organization_pan === userpan);

    if (duplicatePAN) {
      Swal.fire({
        icon: 'error',
        title: 'Duplicate PAN Detected',
        text: 'Declarant PAN and Recipient PAN cannot be the same. Please enter a unique PAN.',
      });
    }

    // if (isDuplicateOrgPAN) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Duplicate Organization PAN Detected',
    //     text: 'Your PAN cannot be the same as any recipient’s organization PAN. Please enter a unique PAN.',
    //   });
    // }
  }, [userpan, recipientDetails]);

  const [recipientSuggestions, setRecipientSuggestions] = useState([[]]); // Initialize with an empty array
  const [finalRecipientEmailOptions, setFinalRecipientEmailOptions] = useState([]);

  // Function to fetch email data from the API
  const fetchRecipientEmailDataFromAPI = async (email) => {
    const parameters = { email: email.trim() };

    try {
      const response = await getServices('user/get-details-based-on-email-detail/', parameters);

      if (response.status === 200) {
        // Store the fetched data
        setFinalRecipientEmailOptions(Array.isArray(response.data.data) ? response.data.data : []);
      } else {
        console.error('Failed to fetch email data:', response.data.msg);
        setFinalRecipientEmailOptions([]);
      }
    } catch (error) {
      console.error('Error fetching email data:', error);
      setFinalRecipientEmailOptions([]);
    }
  };

  // Function to fetch recipient suggestions
  const onRecipientSuggestionsFetchRequested = (index, value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    // Filter suggestions based on the fetched email options
    const filteredSuggestions = inputLength === 0 ? [] : finalRecipientEmailOptions.filter(option =>
      option.email.toLowerCase().includes(inputValue)
    );

    // Set suggestions for the specific recipient
    setRecipientSuggestions(prev => {
      const newSuggestions = [...prev];
      newSuggestions[index] = filteredSuggestions;
      return newSuggestions;
    });
  };

  // Function to clear recipient suggestions
  const onRecipientSuggestionsClearRequested = (index) => {
    setRecipientSuggestions(prev => {
      const newSuggestions = [...prev];
      newSuggestions[index] = [];
      return newSuggestions;
    });
  };

  // Function to handle email change and auto-fill other fields
  const handleRecipientEmailChange = (index, newValue) => {
    const updatedRecipients = [...recipientDetails];

    updatedRecipients[index].email = newValue;

    const duplicateEmail = recipientDetails.some((recipient, i) => i !== index && recipient.email === newValue);

    if (duplicateEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Duplicate Email',
        text: 'This email has already been added. Please enter a different email.',
      });
  
      setErrors({
        ...errors,
        [`recipientDetails_${index}_email`]: 'This email is already in use.',
      });
    } else {
      // Remove any existing email error for this recipient
      const updatedErrors = { ...errors };
      delete updatedErrors[`recipientDetails_${index}_email`];
      setErrors(updatedErrors);
    }

    // If newValue is empty, reset all related fields
    if (newValue === '') {
      updatedRecipients[index] = {
        email: newValue,
        full_name: '',
        designation: '',
        pan: '',
        organization: '',
        organization_pan: '',
        mail_sent: '1'
      };
    } else {
      // Otherwise, just update the email field
      updatedRecipients[index] = { ...updatedRecipients[index], email: newValue };

      // Fetch email data from the API when email changes
      fetchRecipientEmailDataFromAPI(newValue);
    }

    const updatedErrors = { ...errors };

    // Remove the error if the email field is not empty
    if (newValue.trim() !== '') {
        delete updatedErrors[`recipientDetails_${index}_email`];
    } else {
        // Add the error back if the email field is empty
        updatedErrors[`recipientDetails_${index}_email`] = 'Email is required.';
    }

    setErrors(updatedErrors);

    setRecipientDetails(updatedRecipients);
  };

  // Function to handle suggestion selection and auto-fill fields
  const onRecipientSuggestionSelected = (index, suggestion) => {
    const updatedRecipients = [...recipientDetails];
    updatedRecipients[index] = {
      ...updatedRecipients[index],
      email: suggestion.email,
      full_name: suggestion.full_name,
      designation: suggestion.designation,
      pan: suggestion.pan || '',
      organization: suggestion.organization,
      organization_pan: suggestion.organization_pan || '',
      self_identifier_type_if_no_pan: suggestion.self_identifier_type_if_no_pan || '',
      self_other_identity_number_if_no_pan: suggestion.self_other_identity_number_if_no_pan || '',
      self_other_identity_if_no_pan: suggestion.self_other_identity_if_no_pan || '',
    };
    setRecipientDetails(updatedRecipients);
  
    // Remove errors for auto-filled fields
    const updatedErrors = { ...errors };
    delete updatedErrors[`recipientDetails_${index}_full_name`];
    delete updatedErrors[`recipientDetails_${index}_pan`];
    delete updatedErrors[`recipientDetails_${index}_organization`];
    delete updatedErrors[`recipientDetails_${index}_organization_pan`];
    delete updatedErrors[`recipientDetails_${index}_self_identifier_type_if_no_pan`];
    delete updatedErrors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`];
    delete updatedErrors[`recipientDetails_${index}_self_other_identity_if_no_pan`];
  
    setErrors(updatedErrors);
  };  

  // Handles changes for other recipient fields
  const handleRecipientFieldChange = (index, field, value) => {
    const updatedRecipients = [...recipientDetails];
    updatedRecipients[index] = {
      ...updatedRecipients[index],
      [field]: value,
    };

    updatedRecipients[index][field] = value;

    // Check for duplicate PAN (if changing the PAN field)
    if (field === 'pan') {
      const duplicatePAN = recipientDetails.some((recipient, i) => i !== index && recipient.pan === value);

    if (duplicatePAN) {
      Swal.fire({
        icon: 'error',
        title: 'Duplicate PAN',
        text: 'This PAN has already been added. Please enter a different PAN.',
      });

      setErrors({
        ...errors,
        [`recipientDetails_${index}_pan`]: 'This PAN is already in use.',
      });
    } else {
      // Remove any existing PAN error for this recipient
      const updatedErrors = { ...errors };
      delete updatedErrors[`recipientDetails_${index}_pan`];
      setErrors(updatedErrors);
    }
  }

    const updatedErrors = { ...errors };

    // Remove the error if the field is not empty
    if (value.trim() !== '') {
        delete updatedErrors[`recipientDetails_${index}_${field}`];
    } else {
        // Add the error back if the field is empty
        updatedErrors[`recipientDetails_${index}_${field}`] = `The ${field.replace('_', ' ')} is required.`;
    }

    setErrors(updatedErrors);

    setRecipientDetails(updatedRecipients);
  };

  // Remove recipient details handler
  const removeRecipientDetails = (index) => {
    const updatedRecipients = recipientDetails.filter((_, i) => i !== index);
    setRecipientDetails(updatedRecipients);
    // Clear the corresponding suggestions
    setRecipientSuggestions(prev => {
      const newSuggestions = [...prev];
      newSuggestions.splice(index, 1); // Remove the suggestions for the removed recipient
      return newSuggestions;
    });
  };
  
  const [validationErrors, setValidationErrors] = useState([]);  // Store validation errors

  const checkForDuplicates = () => {
    let errors = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const panSet = new Set();
    const organizationPanSet = new Set();

    recipientDetails.forEach((recipient, index) => {
        recipientDetails.forEach((otherRecipient, otherIndex) => {
            if (index !== otherIndex) {
                if (recipient.email && otherRecipient.email === recipient.email) {
                    errors.push({ index, field: 'email', message: 'Email is already used.' });
                }
                if (recipient.full_name && otherRecipient.full_name === recipient.full_name) {
                    errors.push({ index, field: 'full_name', message: 'Full Name is already used.' });
                }
                if (recipient.pan && otherRecipient.pan === recipient.pan) {
                    errors.push({ index, field: 'pan', message: 'PAN is already used.' });
                }
                if (recipient.organization_pan && otherRecipient.organization_pan === recipient.organization_pan) {
                    errors.push({ index, field: 'organization_pan', message: 'Organization PAN is already used.' });
                }
            }
        });

        // Email validation
        if (!recipient.email) {
            errors.push({ index, field: 'email', message: 'Email is required.' });
        } else if (!emailRegex.test(recipient.email)) {
            errors.push({ index, field: 'email', message: 'Please enter a valid email address.' });
        }

        // Full name validation
        if (!recipient.full_name) {
            errors.push({ index, field: 'full_name', message: 'Full Name is required.' });
        }

        // Designation validation
        if (!recipient.designation) {
            errors.push({ index, field: 'designation', message: 'Designation is required.' });
        }

        // Department validation
        if (!recipient.department) {
            errors.push({ index, field: 'department', message: 'Department is required.' });
        }

        // Organization name validation
        if (!recipient.organization) {
            errors.push({ index, field: 'organization', message: 'Organization Name is required.' });
        }

        // Organization PAN or Other Identifier validation
        if (!recipient.organization_pan) {
            if (!recipient.if_no_organization_pan_then_other || !recipient.other_than_organization_pan_identifier_number) {
                errors.push({ index, field: 'organization_pan', message: 'Enter Organization PAN or provide both Other Identifier fields.' });

                if (!recipient.if_no_organization_pan_then_other) {
                    errors.push({ index, field: 'if_no_organization_pan_then_other', message: 'Organization Identifier Type is required.' });
                }

                if (!recipient.other_than_organization_pan_identifier_number) {
                    errors.push({ index, field: 'other_than_organization_pan_identifier_number', message: 'Organization Identifier Number is required.' });
                }

                // If organization identifierType is "5001", ensure organization otherIdentifier is provided
                if (recipient.if_no_organization_pan_then_other === "5001" && !recipient.other_than_organization_pan_identifier_type) {
                    errors.push({ index, field: 'other_than_organization_pan_identifier_type', message: 'Please specify Other Organization Identifier Type.' });
                }
            }
        } else {
            // Organization PAN format validation
            if (!panRegex.test(recipient.organization_pan)) {
                errors.push({ index, field: 'organization_pan', message: `Invalid Organization PAN format for recipient ${index + 1}.` });
            } else if (recipient.pan && recipient.organization_pan && recipient.pan === recipient.organization_pan) {
                errors.push({ index, field: 'organization_pan', message: `Recipient ${index + 1} organization PAN cannot be the same as Recipient PAN.` });
            } else if (recipient.organization_pan === userpan) {
                errors.push({ index, field: 'organization_pan', message: `Recipient ${index + 1} organization PAN cannot be the same as declare PAN.` });
            } 
            // else if (organizationPanSet.has(recipient.organization_pan)) {
            //     errors.push({ index, field: 'organization_pan', message: `Organization PAN for recipient ${index + 1} must be unique.` });
            // } 
            else {
                organizationPanSet.add(recipient.organization_pan);
            }
        }

        // Mail sent option validation
        if (!recipient.mail_sent) {
            errors.push({ index, field: 'mail_sent', message: 'Confidentiality Notice selection is required.' });
        }

        // PAN and identifier fields validation
        if (!recipient.pan) {
            // If PAN is missing, both identifierType and identifierValue are required
            if (!recipient.self_identifier_type_if_no_pan || !recipient.self_other_identity_number_if_no_pan) {
                errors.push({ index, field: 'pan', message: 'Enter PAN or both Other Identifier fields are required.' });

                if (!recipient.self_identifier_type_if_no_pan) {
                    errors.push({ index, field: 'self_identifier_type_if_no_pan', message: 'Other Identifier Type is required.' });
                }

                if (!recipient.self_other_identity_number_if_no_pan) {
                    errors.push({ index, field: 'self_other_identity_if_no_pan', message: 'Other Identifier Number is required.' });
                }

                if (recipient.self_identifier_type_if_no_pan === "5001" && !recipient.self_other_identity_if_no_pan) {
                    errors.push({ index, field: 'self_identifier_type_if_no_pan', message: 'Please specify Other Identifier Type.' });
                }
            }
        } else {
            // Validate PAN format
            if (!panRegex.test(recipient.pan)) {
                errors.push({ index, field: 'pan', message: 'Invalid PAN format.' });
            } else if (panSet.has(recipient.pan)) {
                errors.push({ index, field: 'pan', message: 'PAN must be unique.' });
            } else {
                panSet.add(recipient.pan);
            }
        }
    });

    setValidationErrors(errors);
    return errors.length === 0;
};


  // const addRecipientDetails = () => {
  //   if (checkForDuplicates()) { 
  //     const newRecipient = {
  //       email: '',
  //       full_name: '',
  //       designation: '',
  //       pan: '',
  //       organization: '',
  //       organization_pan: '',
  //       mail_sent: 'Yes',
  //     };
  //     setRecipientDetails([...recipientDetails, newRecipient]);
  //     setRecipientSuggestions([...recipientSuggestions, []]);
  //   }
  // };

  const addRecipientDetails = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    const lastRecipient = recipientDetails[recipientDetails.length - 1];

    const fieldsToCheck = ['email', 'full_name', 'designation', 'department', 'organization', 'mail_sent'];
    let isValid = true;
    let validationErrors = { ...errors };

    fieldsToCheck.forEach((field) => {
        if (!lastRecipient || !lastRecipient[field] || lastRecipient[field].trim() === '') {
            isValid = false;
            validationErrors[`recipientDetails_${recipientDetails.length - 1}_${field}`] = `The ${field.replace('_', ' ')} is required.`;
        }
    });

    if (lastRecipient) {
        if (lastRecipient.email && !emailRegex.test(lastRecipient.email)) {
            isValid = false;
            validationErrors[`recipientDetails_${recipientDetails.length - 1}_email`] = 'Invalid email format.';
        }

        if (lastRecipient.pan && !panRegex.test(lastRecipient.pan)) {
            isValid = false;
            validationErrors[`recipientDetails_${recipientDetails.length - 1}_pan`] = 'Invalid PAN format.';
        }

        if (lastRecipient.organization_pan && !panRegex.test(lastRecipient.organization_pan)) {
            isValid = false;
            validationErrors[`recipientDetails_${recipientDetails.length - 1}_organization_pan`] = 'Invalid Organization PAN format.';
        }

        // Validate organization identifierType and identifierValue if organization_pan is missing
        if (!lastRecipient.organization_pan) {
            if (!lastRecipient.if_no_organization_pan_then_other || !lastRecipient.other_than_organization_pan_identifier_number) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_organization_pan`] = 'Enter Organization PAN or provide both Other Identifier fields.';

                if (!lastRecipient.if_no_organization_pan_then_other) {
                    validationErrors[`recipientDetails_${recipientDetails.length - 1}_if_no_organization_pan_then_other`] = 'Organization Identifier Type is required.';
                }

                if (!lastRecipient.other_than_organization_pan_identifier_number) {
                    validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_number`] = 'Organization Identifier Number is required.';
                }

                if (lastRecipient.if_no_organization_pan_then_other === "5001" && !lastRecipient.other_than_organization_pan_identifier_type) {
                    validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_type`] = 'Please specify Other Organization Identifier Type.';
                }
            }
        } else {
            // If organization PAN is provided, remove validation errors for identifierType and identifierValue
            delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_if_no_organization_pan_then_other`];
            delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_number`];
            delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_type`];
        
            // New conditions for when PAN is entered
          if (lastRecipient.if_no_organization_pan_then_other === "5001") {
            // If identifier type is "Other," both other_than_organization_pan_identifier_type and self_other_identity_number_if_no_pan are required
            if (!lastRecipient.other_than_organization_pan_identifier_type) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_type`] = 'Please specify Other Organization Identifier Type When Identifier Type is Other.';
            }
            if (!lastRecipient.other_than_organization_pan_identifier_number) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_number`] = 'Organization Identifier Number is required when Identifier Type is Other.';
            }
        } else if (lastRecipient.if_no_organization_pan_then_other && lastRecipient.if_no_organization_pan_then_other !== "5001") {
            // If identifier type is any value other than "5001", only other_than_organization_pan_identifier_number is required
            if (!lastRecipient.other_than_organization_pan_identifier_number) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_other_than_organization_pan_identifier_number`] = 'Organization Identifier Number is required for selected Identifier Type.';
            }
        }
      }

      if (!lastRecipient.pan) {
        // When PAN is not entered, we validate the identifier fields
        if (!lastRecipient.self_identifier_type_if_no_pan || !lastRecipient.self_other_identity_number_if_no_pan) {
            isValid = false;
            validationErrors[`recipientDetails_${recipientDetails.length - 1}_pan`] = 'Enter PAN or both Other Identifier fields are required.';
    
            if (!lastRecipient.self_identifier_type_if_no_pan) {
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_identifier_type_if_no_pan`] = 'Other Identifier Type is required.';
            }
    
            if (!lastRecipient.self_other_identity_number_if_no_pan) {
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_number_if_no_pan`] = 'Other Identifier Number is required.';
            }
    
            // New condition: If "5001" is selected in self_identifier_type_if_no_pan
            if (lastRecipient.self_identifier_type_if_no_pan === "5001") {
                if (!lastRecipient.self_other_identity_if_no_pan) {
                    isValid = false;
                    validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_if_no_pan`] = 'Please specify Other Identifier Type when Identifier Type is Other.';
                }
                if (!lastRecipient.self_other_identity_number_if_no_pan) {
                    isValid = false;
                    validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_number_if_no_pan`] = 'Identifier Number is required when Identifier Type is Other.';
                }
            }
        } else {
          // If both Other Identifier fields are filled, remove the PAN error
          delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_pan`];
      }
    } 
    else {
        // If PAN is entered, remove validation errors for identifier fields
        delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_identifier_type_if_no_pan`];
        delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_if_no_pan`];
        delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_number_if_no_pan`];
        delete validationErrors[`recipientDetails_${recipientDetails.length - 1}_pan`];
    
        // New conditions for when PAN is entered
        if (lastRecipient.self_identifier_type_if_no_pan === "5001") {
            // If identifier type is "Other," both self_other_identity_if_no_pan and self_other_identity_number_if_no_pan are required
            if (!lastRecipient.self_other_identity_if_no_pan) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_if_no_pan`] = 'Please specify Other Identifier Type when Identifier Type is Other.';
            }
            if (!lastRecipient.self_other_identity_number_if_no_pan) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_number_if_no_pan`] = 'Identifier Number is required when Identifier Type is Other.';
            }
        } else if (lastRecipient.self_identifier_type_if_no_pan && lastRecipient.self_identifier_type_if_no_pan !== "5001") {
            // If identifier type is any value other than "5001", only self_other_identity_number_if_no_pan is required
            if (!lastRecipient.self_other_identity_number_if_no_pan) {
                isValid = false;
                validationErrors[`recipientDetails_${recipientDetails.length - 1}_self_other_identity_number_if_no_pan`] = 'Identifier Number is required for selected Identifier Type.';
            }
        }
    }
    
    }

    if (!isValid) {
        setErrors(validationErrors);
        Swal.fire({
            icon: 'error',
            title: 'Incomplete Recipient Details',
            text: 'Please fill all recipient details correctly before adding a new one.',
        });
        return;
    }

    // Proceed to add new recipient if validation passes
    const newRecipient = {
        email: '',
        full_name: '',
        designation: '',
        department: '',
        pan: '',
        organization: '',
        organization_pan: '',
        mail_sent: '1', 
        identifierType: '', 
        identifierValue: '', 
        otherIdentifier: '', 
        other_identity:'',
        other_identity_number:'',
        organization_otherIdentifier:'',
    };

    setRecipientDetails([...recipientDetails, newRecipient]);
    setErrors({});
  };


  // Function to display validation errors below fields
  // const renderValidationError = (index, field) => {
  //   const error = validationErrors.find((err) => err.index === index && err.field === field);
  //   return error ? <div className="text-danger">{error.message}</div> : null;
  // };
  

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="mt-3 mb-5" id="form-header">Structural Digital Database under SEBI Insider Trading Regulations</h2>
            <form onSubmit={handleSubmit}>
              {/* Section 1 */}
              <div className="section-wrapper">
                <div className="section-title">
                <h5 className="mb-1 form_lable">1. Details of the Employee sharing the UPSI:</h5>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="email">Email Id <span className='startreq'>*</span></label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                          placeholder: 'Enter Email Id',
                          value: useremail,
                          onChange: onChange
                        }}
                      />
                      {errors.useremail && <span className="text-danger error_text_msg">{errors.useremail}</span>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="full_name">Full Name <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        placeholder="Enter Name"
                        value={userName}
                        // onChange={(e) => setUserName(e.target.value)} 
                        onChange={(e) => {
                          setUserName(e.target.value);    // Allow manual input if needed
                          handleFieldChange('userName', e.target.value);  // Handle validation
                        }}
                        
                      />
                      {errors.userName && <span className="text-danger error_text_msg">{errors.userName}</span>}
                    </div>
                  </div>
                </div>

                {/* PAN and Designation */}
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="form-group1">
                      {/* {!identifierType && <span className='startreq'>*</span>} */}
                      <label className='form_lable' htmlFor="pan">PAN <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id="pan"
                        placeholder="Enter PAN Number"
                        value={userpan}
                        // onChange={(e) => setUserPAN(e.target.value)} 
                        onChange={(e) => {
                          const uppercasePAN = e.target.value.toUpperCase(); // Convert input to uppercase
                          setUserPAN(uppercasePAN); // Update the PAN state with uppercase value
                          handleFieldChange('userpan', uppercasePAN); // Clear errors dynamically
                        }}
                      />
                      {errors.userpan && <span className="text-danger error_text_msg">{errors.userpan}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className='form_lable' htmlFor="identifierType">Other Identifier Type (If PAN Not Available) {identifierType && <span className='startreq'>*</span>}</label>
                    <select
                      className="form-control form-select"
                      value={identifierType}
                      onChange={handleChangeforindentifier}
                    >
                      <option value="" >Select Other Identifier Type </option>
                      {otherDocTypeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                      <option value="5001">Other</option>
                    </select>
                    {errors.identifierType && <span className="text-danger error_text_msg">{errors.identifierType}</span>}
                  </div>

                  {identifierType === "5001" && (
                    <div className="col-md-6 mt-4">
                    <label className='form_lable' htmlFor="otherIdentifier">Please Specify Other Identifier Type <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={otherIdentifier}
                        onChange={(e) => setOtherIdentifier(e.target.value)}
                        placeholder="Enter Other Identifier Type"
                      />
                      {errors.otherIdentifier && (
                        <span className="text-danger error_text_msg">{errors.otherIdentifier}</span>
                      )}
                    </div>
                  )}


                  <div className="col-md-6 mt-4">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="identifierNumber">Other Identifier Number Issued By The Government {identifierType && <span className='startreq'>*</span>}</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="userpan" 
                        placeholder="Enter Other Identifier Number" 
                        value={identifierValue} 
                        onChange={handleIdentifierValueChange} 
                      />
                      {errors.identifierValue && <span className="text-danger error_text_msg">{errors.identifierValue}</span>}
                    </div>
                  </div>

                  <div className="col-md-6 mt-4">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="designation">Designation <span className='startreq'>*</span></label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="userdesignation" 
                        placeholder="Enter Designation" 
                        value={userdesignation} 
                        // onChange={handleDesignationValueChange} 
                        onChange={(e) => {
                          handleDesignationValueChange(e);  // Keep the previous function
                          handleFieldChange('userdesignation', e.target.value);  // Add handleFieldChange
                        }} 
                      />
                      {errors.userdesignation && <span className="text-danger error_text_msg">{errors.userdesignation}</span>}
                    </div>
                  </div>
                
                  <div className="col-md-6 mt-4">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="department">Department <span className='startreq'>*</span></label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="userdepartment" 
                        placeholder="Enter Department" 
                        value={userdepartment} 
                        // onChange={handleDepartmentValueChange} 
                        onChange={(e) => {
                          handleDepartmentValueChange(e);  // Keep the previous function
                          handleFieldChange('userdepartment', e.target.value);  // Add handleFieldChange
                        }} 
                      />
                       {errors.userdepartment && <span className="text-danger error_text_msg">{errors.userdepartment}</span>}
                    </div>
                  </div>
                </div>
              </div>

              {/* Section 2 */}
              <div className="section-wrapper">
                <div className="section-title">
                    <h5 className="mt-1 form_lable">2. Purpose for which UPSI is shared (in brief) <span className='startreq'>*</span></h5>
                </div>
                <div className="form-group1 mt-3 new_upsi">
                 <Select
                    options={purposeUPSIOptions}
                    value={purposeUPSIOptions.find(option => option.id === selectedPurpose)} // Find the selected option
                    // onChange={handleChange}
                    onChange={(selectedOption) => {
                      handleChange(selectedOption);  // Keep the previous function
                      handleFieldChange('selectedPurpose', selectedOption);  // Add handleFieldChange
                    }} 
                    placeholder="Select Purpose"
                  />
                {errors.selectedPurpose && <span className="text-danger error_text_msg">{errors.selectedPurpose}</span>}
                </div>
                {selectedPurpose === '5001' && (
                  <>
                    <input
                      type="text"
                      value={otherPurpose}
                      onChange={(e) => setOtherPurpose(e.target.value)}
                      placeholder="Please Specify Purpose For Which UPSI Is Shared"
                      className="form-control mt-2"
                    />
                    {errors.otherPurpose && <span className="text-danger error_text_msg">{errors.otherPurpose}</span>}
                  </>
                )}
              </div>

              {/* Section 3 */}
              <div className="section-wrapper">
                <div className="section-title">
                <h5 className="mt-1 form_lable">3. Nature of UPSI shared (in brief) <span className='startreq'>*</span></h5>
                </div>
                <div className="form-group1 mt-3 new_upsi">
                <Select
                  options={upsiNatureOptions}
                  onChange={(selectedOption) => {
                    handleUPSISelectChange(selectedOption);  // Keep the previous function
                    handleFieldChange('selectedUPSI', selectedOption);  // Add handleFieldChange
                  }} 
                  value={upsiNatureOptions.find(option => option.id === selectedUPSI)} 
                  // onChange={handleUPSISelectChange} 
                  placeholder="Select Nature Of UPSI Shared"
                  
                />
                {errors.selectedUPSI && <span className="text-danger error_text_msg">{errors.selectedUPSI}</span>}
                </div>

                {selectedUPSI === '5001' && (
                  <>
                    <input
                      type="text"
                      value={otherUPSI}
                      onChange={(e) => setOtherUPSI(e.target.value)}
                      placeholder="Please Specify Nature Of UPSI Shared"
                      className="form-control mt-2"
                    />
                    {errors.otherUPSI && <span className="text-danger error_text_msg">{errors.otherUPSI}</span>}
                  </>
                )}
              </div>

              {/* Section 4 */}
              <div className="section-wrapper">
                <div className="section-title"> 
                    <h5 className="mt-1 form_lable">4. UPSI sharing Period </h5>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="start-date">Start Date <span className='startreq'>*</span></label>
                      <input
                        type="date"
                        id="start-date"
                        value={startDate}
                        className="form-control"
                        // onChange={handleStartDateChange}
                        onChange={(e) => {
                          handleStartDateChange(e);  // Keep the previous function
                          handleFieldChange('startDate', e.target.value);  // Add handleFieldChange
                        }} 
                        
                      />
                      {errors.startDate && <span className="text-danger error_text_msg">{errors.startDate}</span>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group1">
                      <label className='form_lable' htmlFor="end-date">End Date <span className='startreq'>*</span></label>
                      <input
                        type="date"
                        id="end-date"
                        value={endDate}
                        className="form-control"
                        min={startDate || ''}
                        // onChange={(e) => setEndDate(e.target.value)}
                        onChange={(e) => {
                          setEndDate(e.target.value);  // Keep the previous function
                          handleFieldChange('endDate', e.target.value);  // Add handleFieldChange
                        }} 
                        disabled={!startDate}
                        
                      />
                      {errors.endDate && <span className="text-danger error_text_msg">{errors.endDate}</span>}
                    </div>
                  </div>
                </div>

                {/* Remark input */}
                <div className="form-group1 mt-4">
                  <label className='form_lable' htmlFor="remark">Remark, if any</label>
                  {/* <textarea type="text" className="form-control" id="remark"></textarea> */}
                  <textarea
                      type="text"
                      className="form-control"
                      id="remark"
                      value={remark}  
                      onChange={handleRemarkChange} 
                  ></textarea>
                </div>
              </div>

              {/* Section 5: Recipient Details */}

              {recipientDetails.map((recipient, index) => (
                <div key={index} className="section-wrapper">
                  <div className="section-title">
                    <h5 className="mt-1 form_lable">5. Recipient Details With Whom UPSI Is Being Shared {index + 1}</h5>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label className='form_lable' htmlFor={`recipient-email-${index}`}>Email Id <span className='startreq'>*</span></label>
                      <Autosuggest
                        suggestions={recipientSuggestions[index] || []} 
                        onSuggestionsFetchRequested={({ value }) => onRecipientSuggestionsFetchRequested(index, value)}
                        onSuggestionsClearRequested={() => onRecipientSuggestionsClearRequested(index)}
                        getSuggestionValue={(suggestion) => suggestion.email}
                        renderSuggestion={(suggestion) => <div>{suggestion.email}</div>}
                        inputProps={{
                          placeholder: 'Enter Email Id',
                          value: recipient.email,
                          onChange: (event, { newValue }) => handleRecipientEmailChange(index, newValue)
                        }}
                        onSuggestionSelected={(event, { suggestion }) => onRecipientSuggestionSelected(index, suggestion)}
                      />
                      {/* {renderValidationError(index, 'email')}   */}
                      {errors[`recipientDetails_${index}_email`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_email`]}</span>}
                      {errors[`recipient_${index}_email`] && <div className="text-danger">{errors[`recipient_${index}_email`]}</div>} 
                    </div>
                    <div className="col-md-6">
                      <label className='form_lable' htmlFor={`recipient-full_name-${index}`}>Full Name <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-full_name-${index}`}
                        value={recipient.full_name}
                        onChange={(e) => handleRecipientFieldChange(index, 'full_name', e.target.value)}
                        placeholder="Enter Name"
                        
                      />
                      {/* {renderValidationError(index, 'full_name')} */}
                      {errors[`recipientDetails_${index}_full_name`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_full_name`]}</span>}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label className='form_lable' htmlFor={`recipient-pan-${index}`}>PAN <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-pan-${index}`}
                        value={recipient.pan}
                        // onChange={(e) => handleRecipientFieldChange(index, 'pan', e.target.value)}
                        onChange={(e) => {
                          const uppercasePAN = e.target.value.toUpperCase(); // Convert input to uppercase
                          handleRecipientFieldChange(index, 'pan', uppercasePAN); // Update recipient PAN with uppercase value
                        }}
                        placeholder="Enter PAN Number"
                      />
                      {/* {renderValidationError(index, 'pan')} */}
                      {errors[`recipientDetails_${index}_pan`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_pan`]}</span>}
                      {errors[`recipient_${index}_pan`] && <div className="text-danger">{errors[`recipient_${index}_pan`]}</div>}
                    </div>
                      {/* Identifier Type Dropdown */}
                    <div className="col-md-6">
                      <label className='form_lable' htmlFor={`recipient-self_identifier_type_if_no_pan-${index}`}>Other Identifier Type (If PAN Not Available) {recipient.self_identifier_type_if_no_pan && <span className='startreq'>*</span>}</label>
                        <select
                          className="form-control form-select"
                          value={recipient.self_identifier_type_if_no_pan}
                          onChange={(e) => handleRecipientFieldChange(index, 'self_identifier_type_if_no_pan', e.target.value)}
                        >
                          <option value="">Select Other Identifier Type</option>
                          {otherDocTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                          <option value="5001">Other</option>
                        </select>
                        {errors[`recipientDetails_${index}_self_identifier_type_if_no_pan`] && (
                          <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_self_identifier_type_if_no_pan`]}</span>
                        )}
                    </div>
                      {recipient.self_identifier_type_if_no_pan === "5001" && (
                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-self_other_identity_if_no_pan-${index}`}>
                        Please Specify Other Identifier Type <span className='startreq'>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={recipient.self_other_identity_if_no_pan}
                        onChange={(e) => handleRecipientFieldChange(index, 'self_other_identity_if_no_pan', e.target.value)}
                        placeholder="Enter Other Identifier Type"
                      />
                      {errors[`recipientDetails_${index}_self_other_identity_if_no_pan`] && (
                        <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_self_other_identity_if_no_pan`]}</span>
                      )}
                    </div>
                      )}

                    {/* Identifier Number Field */}
                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-self_other_identity_number_if_no_pan-${index}`}>
                        Other Identifier Number Issued By The Government {recipient.self_identifier_type_if_no_pan && <span className='startreq'>*</span>}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Other Identifier Number"
                        value={recipient.self_other_identity_number_if_no_pan}
                        onChange={(e) => handleRecipientFieldChange(index, 'self_other_identity_number_if_no_pan', e.target.value)}
                      />
                      {errors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`] && (
                        <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_self_other_identity_number_if_no_pan`]}</span>
                      )}
                    </div>

                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-designation-${index}`}>Designation <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-designation-${index}`}
                        value={recipient.designation}
                        onChange={(e) => handleRecipientFieldChange(index, 'designation', e.target.value)}
                        placeholder="Enter Designation"
                        
                      />
                      {/* {renderValidationError(index, 'designation')} */}
                      {errors[`recipientDetails_${index}_designation`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_designation`]}</span>}
                    </div>

                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-department-${index}`}>Department  <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-department-${index}`}
                        value={recipient.department}
                        onChange={(e) => handleRecipientFieldChange(index, 'department', e.target.value)}
                        placeholder="Enter Department"
                        
                      />
                      {/* {renderValidationError(index, 'designation')} */}
                      {errors[`recipientDetails_${index}_department`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_department`]}</span>}
                    </div>

                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-organization-${index}`}>Name of the Organization/Entity with which the Person receiving the UPSI is associated <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-organization-${index}`}
                        value={recipient.organization}
                        onChange={(e) => handleRecipientFieldChange(index, 'organization', e.target.value)}
                        placeholder="Enter Organization Name"
                        
                      />
                      {/* {renderValidationError(index, 'organization')} */}
                      {errors[`recipientDetails_${index}_organization`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_organization`]}</span>}
                    </div>
                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-organization_pan-${index}`}>PAN of the Organization/Entity with whom the above Person is associated <span className='startreq'>*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        id={`recipient-organization_pan-${index}`}
                        value={recipient.organization_pan}
                        // onChange={(e) => handleRecipientFieldChange(index, 'organization_pan', e.target.value)}
                        onChange={(e) => {
                          const uppercaseOrganizationPAN = e.target.value.toUpperCase(); // Convert input to uppercase
                          handleRecipientFieldChange(index, 'organization_pan', uppercaseOrganizationPAN); // Update organization PAN with uppercase value
                        }}
                        placeholder="Enter Organization PAN"
                        
                      />
                       {/* {renderValidationError(index, 'organization_pan')} */}
                       {errors[`recipientDetails_${index}_organization_pan`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_organization_pan`]}</span>}
                      {errors[`recipient_${index}_organization_pan`] && <div className="text-danger">{errors[`recipient_${index}_organization_pan`]}</div>}
                    </div>

                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-if_no_organization_pan_then_other-${index}`}>
                        Provide any other identifier of the Organization authorized by law only where PAN is not available {recipient.if_no_organization_pan_then_other && <span className='startreq'>*</span>}
                      </label>
                      <select
                        className="form-control form-select"
                        value={recipient.if_no_organization_pan_then_other}
                        onChange={(e) => handleRecipientFieldChange(index, 'if_no_organization_pan_then_other', e.target.value)}
                      >
                        <option value="">Select Organization Identifier Type</option>
                        {otherOrganizationDocTypeOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                        <option value="5001">Other</option>
                      </select>
                      {errors[`recipientDetails_${index}_if_no_organization_pan_then_other`] && (
                        <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_if_no_organization_pan_then_other`]}</span>
                      )}
                    </div>

                    {/* Conditional Input for "5001" Organization Identifier Type */}
                    {recipient.if_no_organization_pan_then_other === "5001" && (
                      <div className="col-md-6 mt-4">
                        <label className='form_lable' htmlFor={`recipient-other_than_organization_pan_identifier_type-${index}`}>
                          Please Specify Other Identity Type <span className='startreq'>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={recipient.other_than_organization_pan_identifier_type}
                          onChange={(e) => handleRecipientFieldChange(index, 'other_than_organization_pan_identifier_type', e.target.value)}
                          placeholder="Enter Other Identifier Type Of Organization"
                        />
                        {errors[`recipientDetails_${index}_other_than_organization_pan_identifier_type`] && (
                          <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_other_than_organization_pan_identifier_type`]}</span>
                        )}
                      </div>
                    )}

                    {/* Input for Organization Identifier Number */}
                    <div className="col-md-6 mt-4">
                      <label className='form_lable' htmlFor={`recipient-other_than_organization_pan_identifier_number-${index}`}>
                        Other Identifier Number Issued By The Government {recipient.if_no_organization_pan_then_other && <span className='startreq'>*</span>}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Other Identifier Number Of Organization"
                        value={recipient.other_than_organization_pan_identifier_number}
                        onChange={(e) => handleRecipientFieldChange(index, 'other_than_organization_pan_identifier_number', e.target.value)}
                      />
                      {errors[`recipientDetails_${index}_other_than_organization_pan_identifier_number`] && (
                        <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_other_than_organization_pan_identifier_number`]}</span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <label className='form_lable'>Send Confidentiality Notice via email <span className='startreq'>*</span></label>&nbsp;&nbsp;
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={`mail_sentYes-${index}`}
                          name={`mail_sent-${index}`}
                          value="1"
                          checked={recipient.mail_sent === '1'}
                          onChange={(e) => handleRecipientFieldChange(index, 'mail_sent', e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`mail_sentYes-${index}`}>
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={`mail_sentNo-${index}`}
                          name={`mail_sent-${index}`}
                          value="0"
                          checked={recipient.mail_sent === '0'}
                          onChange={(e) => handleRecipientFieldChange(index, 'mail_sent', e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`mail_sentNo-${index}`}>
                          No
                        </label>
                      </div>
                      {/* {renderValidationError(index, 'mail_sent')} */}
                      {errors[`recipientDetails_${index}_mail_sent`] && <span className="text-danger error_text_msg">{errors[`recipientDetails_${index}_mail_sent`]}</span>}
                    </div>
                  </div>

                  {recipientDetails.length > 1 && (
                    <button type="button" className="btn btn-danger mt-3" onClick={() => removeRecipientDetails(index)}>
                      Remove
                    </button>
                  )}
                </div>
              ))}


              <button type="button" className="addMore_btn btn-secondary mt-3 mb-4" onClick={addRecipientDetails}>
                Add More Recipient Details
              </button>


              <div className="section-wrapper">
                <div className="section-title">
                  <h5 className="mt-1 form_lable">6. Editor for Email content</h5>
                </div>

                <div className="form-group1 mt-4">
                  <label className="form_label" htmlFor="remark">Email</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    // onChange={onEditorDataChange} 
                    onChange={(event, editor) => {
                      onEditorDataChange(event, editor);  // Keep the previous function
                      handleFieldChange('mail_content', editor.getData());  // Add handleFieldChange
                    }} 
                    config={{
                      toolbar: [
                        'heading', '|',
                        'bold', 'italic', 'link', '|',
                        'bulletedList', 'numberedList', '|',
                        'insertTable', '|',
                        'undo', 'redo'
                      ],
                    }}
                  />
                   {errors.mail_content && <span className="text-danger error_text_msg">{errors.mail_content}</span>}
                </div>
              </div>

              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  checked={isCheckboxChecked}
                  // onChange={(e) => setIsCheckboxChecked(e.target.checked)} 
                  onChange={(e) => {
                    setIsCheckboxChecked(e.target.checked);  // Keep the previous function
                    handleFieldChange('checkbox', e.target.checked);  // Add handleFieldChange
                  }} 
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Due notice has been given to the recipient to maintain confidentiality of the UPSI
                  and refrain from dealing in securities of Eicher Motors Limited in compliance with
                  SEBI (Prohibition of Insider Trading) Regulations
                </label>
                {errors.checkbox && <span className="text-danger error_text_msg">{errors.checkbox}</span>}
              </div>

            <div className="reg_btn mb-4">
              <button type="submit" className="submit_btn mt-4">Submit</button>
            </div>
            
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Useregistration;
