import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import DataTable from './DataTable';
import { getServices } from './services'; 
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Userregistrationinfo = () => {
    const [data, setData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const pageSize = 5; // Set page size
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async (page, limit, fromDate = null, toDate = null) => {
        try {
            const params = {
                page,
                limit,
            };

            if (fromDate) {
                params.fromDate = fromDate.toISOString().split('T')[0];
            }
            if (toDate) {
                params.toDate = toDate.toISOString().split('T')[0]; 
            }

            const response = await getServices('admin/get-all-declarations/', params);

            if (response.token) {
                Cookies.set('klsajdfaklsfjaslkfjasl', response.token, { expires: 1 });
            }

            const finalData = response.data.data;

            if (Array.isArray(finalData)) {
                finalData.sort((a, b) => b.declaring_person.id - a.declaring_person.id);
                setData(finalData);
                setFilteredData(finalData);
                setTotalRecords(response.data.pagination.totalRecords); 
            } else {
                setData([]);
                setFilteredData([]);
                setTotalRecords(0);
                Swal.fire({
                    icon: 'error',
                    title: 'No Data Found',
                    text: response.data.msg,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong during fetching data. Please try again later.',
            });
        }
    };


    const searchTermFun = async (search) => {
        try {
            const page =1
            const limit = 10
            const params = {
                page,
                limit,
                search
            };

            if (fromDate) {
                params.fromDate = fromDate.toISOString().split('T')[0];
            }
            if (toDate) {
                params.toDate = toDate.toISOString().split('T')[0]; 
            }

            const response = await getServices('admin/get-all-declarations/', params);

            if (response.token) {
                Cookies.set('klsajdfaklsfjaslkfjasl', response.token, { expires: 1 });
            }

            const finalData = response.data.data;

            if (Array.isArray(finalData)) {
                finalData.sort((a, b) => b.declaring_person.id - a.declaring_person.id);
                setData(finalData);
                setFilteredData(finalData);
                setTotalRecords(response.data.pagination.totalRecords); 
            } else {
                setData([]);
                setFilteredData([]);
                setTotalRecords(0);
                Swal.fire({
                    icon: 'error',
                    title: 'No Data Found',
                    text: response.data.msg,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong during fetching data. Please try again later.',
            });
        }
    };


    


    const fetchDataCSVDownload  = async (page, limit, fromDate = null, toDate = null) => {
        try {
            let token = Cookies.get("klsajdfaklsfjaslkfjasl");
            const url = 'admin/get-declaration-details-excel'
            const API_URL =
                process.env.NODE_ENV === "development"
                    ? process.env.REACT_APP_DEV_MODE + url
                    : process.env.REACT_APP_PRO_MODE + url;
    
            // Prepare the body data, ensuring fromDate and toDate are properly handled
            const bodyData = {
                page: page,
                limit: limit,
                search: "",
                fromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
                toDate: toDate ? toDate.toISOString().split('T')[0] : null,
            };
    
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyData),
            });
    
            // Check if the response is ok (status code in the range 200-299)
            if (!response.ok) {
                const errorMessage = await response.text(); // Use .text() if not binary
                throw new Error(`Error: ${response.status} - ${errorMessage}`);
            }
    
            // Assuming the response is an Excel file, handle it as a blob
            const contentType = response.headers.get('Content-Type');
            console.log('Content-Type:', contentType);
    
            if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const blob = await response.blob(); // Handle as a binary file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'declarations.xlsx'; // Set the filename for the download
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } else {
                throw new Error('Unexpected content type: ' + contentType);
            }
        } catch (error) {
            console.error('Error fetching declarations:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to download CSV file. Please try again.',
            });
        }
    };
    
      
    useEffect(() => {
        fetchData(currentPage, pageSize); 
    }, [currentPage]);


    useEffect(() => {
        searchTermFun(searchTerm); 
    }, [searchTerm]);

    

    const handleDateFilter = () => {
        // Validate date range
        if (fromDate && toDate && fromDate > toDate) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Date Range',
                text: 'The "From" date cannot be later than the "To" date.',
            });
            return;
        }

        // Reset to the first page when applying date filters
        setCurrentPage(1);

        fetchData(1, pageSize, fromDate, toDate); // Fetch data starting from page 1 with the selected date range
    };

    

    const handleDownloadCSV = () => {
        // Validate date range
        if (fromDate && toDate && fromDate > toDate) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Date Range',
                text: 'The "From" date cannot be later than the "To" date.',
            });
            return;
        }

        // Reset to the first page when applying date filters
        setCurrentPage(1);

        fetchDataCSVDownload(1, pageSize, fromDate, toDate); // Fetch data starting from page 1 with the selected date range
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'SR. NO.',
                accessor: 'id', 
                Cell: ({ row }) => <span>{row.index + 1 + (currentPage - 1) * pageSize}</span>, // Adjusting for pagination
            },
            {
                Header: 'Email of the Employee sharing the UPSI',
                accessor: 'declaring_person.email',
            },
            {
                Header: 'Name of the Employee sharing the UPSI',
                accessor: 'declaring_person.full_name',
            },
            {
                Header: 'Date Submitted',
                accessor: 'declaring_person.created_at',
            },
            {
                Header: 'Start Date',
                accessor: 'declaring_person.start_date',
            },
            {
                Header: 'End Date',
                accessor: 'declaring_person.end_date',
            },
            {
                Header: 'Action',
                Cell: ({ row }) => (
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleViewUser(row.original.declaring_person.id)} 
                    >
                        View
                    </button>
                ),
            },
        ],
        [currentPage]
    );

  

      
    const handleViewUser = (userId) => {
        navigate(`/user/${userId}`);
    };

    // Pagination controls
    const totalPages = Math.ceil(totalRecords / pageSize);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div className='userinfopage_Title'>
                    <h2 className='d-flex justify-content-start mb-4'>Declarations</h2>

                    {/* Date Range Filters */}
                    <div className="filter-section row align-items-center mb-3 mx-1">
                        <div className="col-12 col-md-3 d-flex align-items-center mb-2 mb-md-0">
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-control me-2 searchBox_datatable"
                            />
                        </div>
                        
                        <div className="col-12 col-md-5 d-flex align-items-center justify-content-center mb-2 mb-md-0">
                            <label className="mx-2 mb-0">From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                className="form-control"
                                placeholderText="Submitted Date"
                                dateFormat="yyyy-MM-dd"
                            />
                            
                            <label className="ms-4 mx-2 mb-0">To:</label>
                            <DatePicker
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                className="form-control"
                                placeholderText="Submitted Date"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        
                        <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end">
                            <button className="btn btn-primary me-2" onClick={handleDateFilter}>
                                Filter
                            </button>
                            <button className="btn btn-primary" onClick={handleDownloadCSV}>
                                Download CSV
                            </button>
                        </div>
                    </div>


                    {/* Data Table */}
                    <DataTable columns={columns} data={filteredData} />
                    
                    {/* Pagination Controls */}
                    <div className="pagination-controls d-flex justify-content-end align-items-center mt-4 mb-2">
                        <button
                            className="btn btn-outline-primary mx-1"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        
                        <div className="pagination-numbers d-flex align-items-center">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index + 1}
                                    className={`btn btn-outline-primary mx-1 ${currentPage === index + 1 ? 'active' : ''}`}
                                    onClick={() => handlePageClick(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                        
                        <button
                            className="btn btn-outline-primary mx-1"
                            onClick={handleNextPage}
                            disabled={currentPage >= totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Userregistrationinfo;
