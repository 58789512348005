import React, { useState } from 'react';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2'; 
import { useNavigate } from 'react-router-dom';
import { getServices } from './services'; 

const Header = () => {
    
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (e) => {
        if (isDropdownOpen && !e.target.closest('#userInfoDropdown')) {
            setDropdownOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpen]);

    const logout = async () => {
        try {
          const response = await getServices('admin/logout', { }); 
    
          if (response.status === 200) {
            Cookies.remove("klsajdfaklsfjaslkfjasl"); 
            window.location.href = "/login"; 
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Logout Failed',
              text: response.data.msg || 'Something went wrong. Please try again.',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while trying to log out. Please try again later.',
          });
        }
      };

      const navigateToChangePassword = () => {
        navigate('/changePassword'); // Use the navigate function for routing
        setDropdownOpen(false); // Close dropdown after navigating
    };

    return (
        <header className="d-flex justify-content-between align-items-center p-3">
            <div className="search-bar">
                {/* <input type="text" placeholder="Search..." className="form-control" />
                <i className="fas fa-search"></i> */}
            </div>
            <div className="user-info" id="userInfoDropdown">
                {/* <img
                    src="https://via.placeholder.com/40"
                    alt="User Avatar"
                    className="avatar rounded-circle"
                /> */}
                <span className='user_name' onClick={toggleDropdown}>Admin</span>
                <i className="fas fa-chevron-down" onClick={toggleDropdown}></i>
                <div
                    className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}
                    id="profileDropdown"
                    style={{ position: 'absolute' }}
                >
                    <div className="dropdown-item" onClick={navigateToChangePassword}>
                        <i className="fas fa-lock"></i> Change Password
                    </div>
                    {/* <a href="#profile" className="dropdown-item"><i className="fas fa-user"></i> Profile</a>
                    <a href="#settings" className="dropdown-item"><i className="fas fa-cog"></i> Settings</a> */}
                    <div className="dropdown-item" onClick={logout}>
                        <i className="fas fa-sign-out-alt"></i> Logout
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
